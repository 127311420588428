import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PostList from "../components/PostList";

const IndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges.map(
    (edge) => edge.node.frontmatter
  );
  return (
    <Layout>
      <SEO title="Home" />
      <PostList posts={posts} title="All Blog Posts" />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 100000
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            date
          }
        }
      }
    }
  }
`;
