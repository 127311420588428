import React from "react";
import { Link } from "gatsby";

const PostList = ({ title, posts }) => {
  return (
    <div>
      <h1 className="text-4xl">{title}</h1>
      <h2 className="text-2xl">{posts.length} posts</h2>
      {posts.map((post, i) => (
        <Link
          key={post.path}
          to={post.path}
          className={`p-2 bg-gray-${
            i % 2 ? "3" : "1"
          }00 block hover:bg-teal-300`}
        >
          <i className="font-mono pr-4 text-sm text-gray-600">{post.date}</i>
          <span className="text-blue-700 text-lg">{post.title}</span>
        </Link>
      ))}
    </div>
  );
};

export default PostList;
